import { emailService } from "../services/emailService";
import { toast } from "react-toastify";

import {
  FETCH_EMAILS_REQUEST,
  FETCH_EMAILS_SUCCESS,
  FETCH_EMAILS_FAILURE,
  DELETE_EMAIL,
  FETCH_EMAIL_REQUEST,
  FETCH_EMAIL_SUCCESS,
  FETCH_EMAIL_FAILURE,
  ADD_EMAIL_REQUEST,
  ADD_EMAIL_SUCCESS,
  ADD_EMAIL_FAILURE,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  FETCH_MULTI_DOCUMENTS_REQUEST,
  FETCH_MULTI_DOCUMENTS_SUCCESS,
  FETCH_MULTI_DOCUMENTS_FAILURE,
} from "../actionTypes";

export const sendEmailing = async (emailData) => {
  const response = await emailService.sendEmailing(emailData);
  return response;
};
export const templatesList = async () => {
  const response = await emailService.templatesList();
  return response;
};
export const addTemplate = async (data) => {
  const response = await emailService.addTemplate(data);
  return response;
};

// Action Creator pour récupérer la liste des emails
export const fetchEmails = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_EMAILS_REQUEST });
    try {
      const emails = await emailService.getAllEmails();
      dispatch({ type: FETCH_EMAILS_SUCCESS, payload: emails });
    } catch (err) {
      dispatch({ type: FETCH_EMAILS_FAILURE, payload: err.message });
    }
  };
};

export const fetchDocuments = (docId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_MULTI_DOCUMENTS_REQUEST });
    try {
      const emails = await emailService.fetchDocuments(docId);
      dispatch({ type: FETCH_MULTI_DOCUMENTS_SUCCESS, payload: emails });
    } catch (err) {
      dispatch({ type: FETCH_MULTI_DOCUMENTS_FAILURE, payload: err.message });
    }
  };
};

export const deleteEmail = (emailId) => {
  return async (dispatch) => {
    try {
      await emailService.deleteEmail(emailId);
      dispatch({ type: DELETE_EMAIL, payload: emailId });
    } catch (err) {
      console.error(err);
    }
  };
};

export const sendEmail = (emailData) => {
  return async (dispatch) => {
    try {
      console.log(emailData);
      await emailService.sendEmail(emailData);
      dispatch({ type: FETCH_EMAIL_SUCCESS, payload: false });
      toast.success("E-mail evoyer avec success");
    } catch (err) {
      console.error(err);
    }
  };
};

// Action Creator pour récupérer un email
export const fetchEmail = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_EMAIL_REQUEST });
    try {
      const email = await emailService.getEmailById(id);
      dispatch({ type: FETCH_EMAIL_SUCCESS, payload: email });
    } catch (err) {
      dispatch({ type: FETCH_EMAIL_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour ajouter un email
export const addEmail = (data) => {
  return async (dispatch) => {
    dispatch({ type: ADD_EMAIL_REQUEST });
    try {
      const email = await emailService.createEmail(data);
      dispatch({ type: ADD_EMAIL_SUCCESS, payload: email });
    } catch (err) {
      dispatch({ type: ADD_EMAIL_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour mettre à jour un email
export const updateEmail = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_EMAIL_REQUEST });
    try {
      const email = await emailService.updateEmail(id, data);
      dispatch({ type: UPDATE_EMAIL_SUCCESS, payload: email });
    } catch (err) {
      dispatch({ type: UPDATE_EMAIL_FAILURE, payload: err.message });
    }
  };
};
