import { useEffect, useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import CreatableSelect from "react-select/creatable";
import {
  sendEmailing,
  templatesList,
  addTemplate,
} from "../../../redux/actions/emailActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NavContext } from "../../NavContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import parse from "html-react-parser";

const EmailingPage = () => {
  const currentUser = useSelector((state) => state.authReducer.user.user);
  const { setActiveNav } = useContext(NavContext);
  const [validEmails, setValidEmails] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const { mutate: mutateSendEmailing, status } = useMutation({
    mutationFn: async (data) => sendEmailing(data),
    onSuccess: () => {
      toast.success("E-mailing en cours d'envoi");
    },
  });
  const { mutate: mutateAddTemplate, status: statusAddTemplate } = useMutation({
    mutationFn: async (data) => addTemplate(data),
    onSuccess: () => {
      refetch();
      toast.success("Template ajouté avec success");
    },
  });

  const { data: templates, refetch } = useQuery({
    queryKey: ["templates"],
    queryFn: () => templatesList(),
  });

  useEffect(() => {
    document.title = "ProdOTop - Emailing";
    setActiveNav("clients");
  }, [setActiveNav]);

  const onSubmitEmail = (data) => {
    const formattedCcEmails = validEmails.join("; ");

    const emailData = {
      ...data,
      send_to: formattedCcEmails,
      template: data?.template?.value,
    };

    mutateSendEmailing(emailData);
    setValidEmails([]);
    reset();
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateEmailList = (emailList) => {
    if (!emailList) return true;
    return emailList.split(";").every((email) => validateEmail(email.trim()));
  };

  const handleCcChange = (event) => {
    const emailList = event.target.value.split(";").map((email) => email.trim());
    const validEmailList = emailList.filter((email) => validateEmail(email));
    setValidEmails(validEmailList);
  };

  const loadTemplate = (displayableTemplate) => {
    const template = templates.find((t) => t.name === displayableTemplate);
    setSelectedTemplate(template ? template : false);
  };

  const pending = status === "pending";
  const pendingTemplate = statusAddTemplate === "pending";

  const templateList =
    templates?.map((option) => ({
      value: option.name,
      label: option.name,
    })) || [];

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              E-Mailings
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        <div>
          <Row className="mb-3">
            <div className="col-12">
              {pending && (
                <div className="flex justify-content-center align-items-center bg-info p-6 rounded-lg shadow-md mb-3 bg-sky gap-3">
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin " size="2x" />
                  <span className="text-bold fs-4">En cours d'envoie</span>
                </div>
              )}

              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header">E-mailings</div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmitEmail)}>
                    <Row className="mb-3">
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="from" className="form-label">
                            E-mail de :
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="from"
                            defaultValue={currentUser.email}
                            {...register("from", { required: true })}
                          />
                          {errors.from && (
                            <div className="text-danger">Objet de l'email requis</div>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="subject" className="form-label">
                            Sujet :
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="subject"
                            {...register("subject", { required: true })}
                          />
                          {errors.subject && (
                            <div className="text-danger">Objet de l'email requis</div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="send_to" className="form-label">
                            {"Envoyé (à) : separation avec < ; >"}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="send_to"
                            {...register("send_to", {
                              validate: (value) =>
                                validateEmailList(value) || "Format d'email invalide",
                            })}
                            onChange={handleCcChange}
                          />
                          {errors.send_to && (
                            <div className="text-danger">{errors.send_to.message}</div>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        {validEmails.length > 0 && (
                          <div className="valid-emails-list">
                            <label className="form-label">Liste :</label>
                            <ul className="flex gap-2">
                              {validEmails.map((email, index) => (
                                <li key={index}>{email}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={8}>
                        <div className="mb-3">
                          <label htmlFor="template" className="form-label">
                            <span>Template</span>
                            {pendingTemplate && (
                              <FontAwesomeIcon
                                icon={faSpinner}
                                className="animate-spin "
                                size="xs"
                              />
                            )}
                          </label>
                          <Controller
                            name="template"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <CreatableSelect
                                {...field}
                                options={templateList}
                                onCreateOption={async (inputValue) => {
                                  const newTemplate = await mutateAddTemplate({
                                    template: inputValue,
                                  });
                                  field.onChange({
                                    value: inputValue,
                                    label: inputValue,
                                  });
                                }}
                                onChange={(selectedOption) => {
                                  loadTemplate(selectedOption?.value);
                                  field.onChange(selectedOption);
                                }}
                                isClearable={true}
                                placeholder="Sélectionnez un template"
                                className="mb-3"
                              />
                            )}
                          />
                          {errors.template && (
                            <div className="text-danger">Template requis</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="text" className="form-label">
                            Ajouter du texte
                          </label>
                          <Controller
                            name={`text`}
                            control={control}
                            defaultValue="" // Valeur par défaut pour éviter undefined/null
                            render={({ field }) => (
                              <CKEditor
                                editor={ClassicEditor}
                                data={field.value || ""} // Utilisez une chaîne vide par défaut
                                onChange={(event, editorInstance) => {
                                  const data = editorInstance.getData();
                                  field.onChange(data);
                                }}
                              />
                            )}
                          />
                          {errors.text && (
                            <div className="text-danger">Texte de l'email requis</div>
                          )}
                        </div>
                      </Col>
                      <Col md={4}>
                        {selectedTemplate && (
                          <DisplayTemplate template={selectedTemplate} />
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={3}>
                        <button type="submit" className="btn bg-gradient-dark w-80 mb-0">
                          Envoyer
                        </button>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </div>
          </Row>

          <Row className="mb-3">
            <Col>
              <Link to={`/`} className="link-action back">
                Retour
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const DisplayTemplate = ({ template }) => {
  return (
    <div className="flex gap-4 ">
      <div
        style={{
          transform: "scale(0.33)",
          transformOrigin: "top left",
        }}
      >
        {parse(template.publish_code)}
      </div>
    </div>
  );
};

export default EmailingPage;
